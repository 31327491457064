import React from "react";
import { useTranslation } from "react-i18next";

import { HeadInject } from "../components/03_organisms/HeadInject/HeadInject";
import Header from "../components/03_organisms/Header/Header";
import { Button } from '../components/01_atoms/Button/Button';
import { Section } from "../components/03_organisms/Section/Section";
import Footer from "../components/03_organisms/Footer/Footer";
import { Script } from "gatsby"
import { Heading } from "../components/01_atoms/Heading/Heading";
import { Helmet } from "react-helmet";
import '../styles/scam-alert.css';

const ScamAlert = ({ data, pageContext, ...props }) => {

    // read language
    const {t} = useTranslation();

    // render html
	return (
        <>
            <div>
                <HeadInject />
                <Header>
                    <Button to="/" size="s">{t('scam_alert_back_cta')}</Button>
                </Header>

                <Section hasContent={true}>

                    <div style={{paddingTop: '75px'}}>
                        <Heading element="h1" size="l" weight="800" spacing="l">{t('scam_alert_title')}</Heading>

                        
                        {/* OneTrust Privacy Notice start */}
                        {/* Container in which the privacy notice will be rendered */}
                        <div id="otnotice-92994717-9f09-4e89-846c-739fdec0a853" className="otnotice"></div>   
                        <script
                            src="https://omnicom-privacy-cdn.my.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js"
                            type="text/javascript" 
                            charset="UTF-8" 
                            id="otprivacy-notice-script"
                            settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vb21uaWNvbS1wcml2YWN5Lm15Lm9uZXRydXN0LmNvbS9yZXF1ZXN0L3YxL3ByaXZhY3lOb3RpY2VzL3N0YXRzL3ZpZXdzIn0="
                        ></script>
                        <script id="otprivacy-notice-settings" dangerouslySetInnerHTML={{ __html: `
                            OneTrust.NoticeApi.Initialized.then(function() {
                                setTimeout(function() {
                                    OneTrust.NoticeApi.LoadNotices(["https://omnicom-privacy-cdn.my.onetrust.com/c0a325be-6f68-46be-a0de-e4a750890f7d/privacy-notices/92994717-9f09-4e89-846c-739fdec0a853.json"]);
                                }, 1250);
                            });
                        `}}></script>                 
                        {/* OneTrust Privacy Notice end */}
                    </div>

                </Section>

                <Footer />
            </div>
        </>
    );
}

export default ScamAlert;